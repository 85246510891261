import React from "react"
import styled from "@emotion/styled"
import { styles } from "../common/styles"

const DEFAULT_COLOR = styles.colors.blue.dark

const SVG_ATTRIBUTES = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "31.193",
  height: "31.193",
  viewBox: "0 0 31.193 31.193",
}

const FacebookLogo = ({ color = DEFAULT_COLOR }) => (
  <svg {...SVG_ATTRIBUTES}>
    <path
      d="M9.259,8.021v2.148H7.687v2.625H9.259v7.8h3.232v-7.8h2.168s.2-1.259.3-2.636H12.5V8.364a.762.762,0,0,1,.7-.63h1.759V5H12.57C9.18,5,9.259,7.628,9.259,8.021Z"
      transform="translate(4.302 2.798)"
      fill={color}
    />
    <path
      d="M15.6,28.074A12.477,12.477,0,1,0,3.119,15.6,12.477,12.477,0,0,0,15.6,28.074Zm0,3.119a15.6,15.6,0,1,1,15.6-15.6A15.6,15.6,0,0,1,15.6,31.193Z"
      fill={color}
    />
  </svg>
)

const TikTokLogo = ({ color = DEFAULT_COLOR }) => (
  <svg {...SVG_ATTRIBUTES}>
    <path
      d="M12.615 24.993a6.143 6.143 0 0 1-2.439-.856 5.668 5.668 0 0 1-1.135-.934 5.84 5.84 0 0 1-1.569-3.068c-.067-.368-.096-1.282-.048-1.675a5.85 5.85 0 0 1 1.617-3.433 5.875 5.875 0 0 1 4.862-1.79c.192.02.361.045.374.058.016.015.016.808.003 1.767l-.022 1.739-.211-.06c-.313-.09-.882-.11-1.221-.042-.975.198-1.742.898-2.049 1.873-.064.21-.073.303-.073.75 0 .493.006.522.105.816a2.716 2.716 0 0 0 2.004 1.793c.297.06.88.045 1.183-.032.76-.201 1.4-.722 1.742-1.425.262-.54.236.195.256-7.464l.016-6.824 1.54-.01 1.538-.006.022.214c.179 1.863 1.09 3.222 2.647 3.941.482.224 1.265.435 1.838.496l.198.022v3.193l-.278-.02c-1.273-.089-2.25-.35-3.293-.875a6.939 6.939 0 0 1-.687-.396l-.313-.211v3.385c0 2.033-.013 3.513-.032 3.71a5.916 5.916 0 0 1-5.133 5.35 7.249 7.249 0 0 1-1.442.014z"
      fill={color}
    />
    <path
      d="M15.6,28.074A12.477,12.477,0,1,0,3.119,15.6,12.477,12.477,0,0,0,15.6,28.074Zm0,3.119a15.6,15.6,0,1,1,15.6-15.6A15.6,15.6,0,0,1,15.6,31.193Z"
      fill={color}
    />
  </svg>
)

const InstagramLogo = ({ color = DEFAULT_COLOR }) => (
  <svg {...SVG_ATTRIBUTES}>
    <g transform="translate(8.219 8.219)">
      <path
        d="M10.7,0H4.541A4.546,4.546,0,0,0,0,4.542V10.7a4.546,4.546,0,0,0,4.542,4.541H10.7A4.546,4.546,0,0,0,15.244,10.7V4.541A4.546,4.546,0,0,0,10.7,0Zm3.008,10.7A3.008,3.008,0,0,1,10.7,13.711H4.541A3.008,3.008,0,0,1,1.533,10.7V4.541A3.008,3.008,0,0,1,4.542,1.533H10.7a3.008,3.008,0,0,1,3.008,3.008V10.7Z"
        fill={color}
      />
      <path
        d="M8.761,4.819a3.943,3.943,0,1,0,3.944,3.942A3.948,3.948,0,0,0,8.761,4.819Zm0,6.352A2.409,2.409,0,1,1,11.17,8.762,2.409,2.409,0,0,1,8.761,11.171Z"
        transform="translate(-1.14 -1.139)"
        fill={color}
      />
      <circle
        cx="0.945"
        cy="0.945"
        r="0.945"
        transform="translate(10.628 2.765)"
        fill={color}
      />
    </g>
    <path
      d="M15.6,28.074A12.477,12.477,0,1,0,3.119,15.6,12.477,12.477,0,0,0,15.6,28.074Zm0,3.119a15.6,15.6,0,1,1,15.6-15.6A15.6,15.6,0,0,1,15.6,31.193Z"
      transform="translate(0)"
      fill={color}
    />
  </svg>
)

const YoutubeLogo = ({ color = DEFAULT_COLOR }) => (
  <svg {...SVG_ATTRIBUTES}>
    <path
      d="M15.6,28.074A12.477,12.477,0,1,0,3.119,15.6,12.477,12.477,0,0,0,15.6,28.074Zm0,3.119a15.6,15.6,0,1,1,15.6-15.6A15.6,15.6,0,0,1,15.6,31.193Z"
      fill={color}
    />
    <path
      d="M17.357,6.526H8.239A3.222,3.222,0,0,0,5,9.73v4.507a3.221,3.221,0,0,0,3.239,3.2h9.118a3.222,3.222,0,0,0,3.239-3.2V9.73A3.221,3.221,0,0,0,17.357,6.526ZM15.167,12.2,10.9,14.215a.172.172,0,0,1-.245-.154V9.912a.172.172,0,0,1,.25-.151L15.17,11.9A.17.17,0,0,1,15.167,12.2Z"
      transform="translate(2.798 3.652)"
      fill={color}
    />
  </svg>
)

const MailLogo = ({ color = DEFAULT_COLOR }) => (
  <svg {...SVG_ATTRIBUTES}>
    <path
      d="M15.6,28.074A12.477,12.477,0,1,0,3.119,15.6,12.477,12.477,0,0,0,15.6,28.074Zm0,3.119a15.6,15.6,0,1,1,15.6-15.6A15.6,15.6,0,0,1,15.6,31.193Z"
      fill={color}
    />
    <path
      d="M137.651,131.615a1.039,1.039,0,0,0,.013-.159q0-.678-.848-.677a.806.806,0,0,0-.549.138.974.974,0,0,0-.2.552,1.849,1.849,0,0,0-.655-.617,2.06,2.06,0,0,0-.867-.145,3.06,3.06,0,0,0-2.511,1.473,4.855,4.855,0,0,0-.977,2.894,3.6,3.6,0,0,0,.634,2.111,2.151,2.151,0,0,0,1.838,1,2.992,2.992,0,0,0,1.863-.7,1.456,1.456,0,0,0,1.374.7,3.981,3.981,0,0,0,3.145-1.552,5.119,5.119,0,0,0,1.243-3.394,4.7,4.7,0,0,0-1.929-3.824,6.4,6.4,0,0,0-4.09-1.418,7.113,7.113,0,0,0-5.036,1.9,6.523,6.523,0,0,0-2.1,4.992,5.672,5.672,0,0,0,2.149,4.767,8.017,8.017,0,0,0,5.062,1.5,8.246,8.246,0,0,0,2.6-.418,6.05,6.05,0,0,0,2.291-1.3,4.023,4.023,0,0,0,.556-.676,1.536,1.536,0,0,0,.33-.783.585.585,0,0,0-.158-.4.492.492,0,0,0-.378-.176.944.944,0,0,0-.7.291q-.471.506-.7.771a5.615,5.615,0,0,1-3.832,1.346,6.025,6.025,0,0,1-3.934-1.207,4.6,4.6,0,0,1-1.612-3.778,5.536,5.536,0,0,1,1.534-3.988,5.128,5.128,0,0,1,3.864-1.6,4.517,4.517,0,0,1,3.048,1.042,3.664,3.664,0,0,1,1.3,2.941,4.773,4.773,0,0,1-.33,1.789,3.134,3.134,0,0,1-1.068,1.431,1.618,1.618,0,0,1-.822.377q-.324,0-.324-.3c0-.084.055-.473.169-1.167Zm-2.492,3.659q-.6,1.653-1.268,1.654-1,0-1-1.282a5.434,5.434,0,0,1,.577-2.245q.673-1.475,1.533-1.475a.565.565,0,0,1,.531.385,1.589,1.589,0,0,1,.142.7A6.983,6.983,0,0,1,135.159,135.274Z"
      transform="translate(-118.679 -118.486)"
      fill={color}
    />
  </svg>
)

export const SocialMediaMap = [
  {
    hrefTo: `mailto:info@patricia-on-ice.de`,
    Logo: MailLogo,
    identifier: "info@patricia-on-ice.de",
    key: "mail",
  },
  {
    hrefTo: "https://www.facebook.com/PatriciaonIce/",
    Logo: FacebookLogo,
    identifier: "PatriciaonIce",
    key: "facebook",
  },
  {
    hrefTo: "https://www.tiktok.com/share/user/196856262641451008",
    Logo: TikTokLogo,
    identifier: "patricia.on.ice",
    key: "tiktok",
  },
  {
    hrefTo: "https://www.instagram.com/patricia.on.ice/",
    Logo: InstagramLogo,
    identifier: "patricia.on.ice",
    key: "instagram",
  },
  {
    hrefTo: "https://www.youtube.com/channel/UCti8iB7XrVkd16lY-xMgEug",
    Logo: YoutubeLogo,
    identifier: "UCti8iB7XrVkd16lY-xMgEug",
    key: "youtube",
  },
]

const SocialMediaLinks = ({
  color = DEFAULT_COLOR,
  height = "100%",
  className,
}) => (
  <Wrapper className={className}>
    {SocialMediaMap.map(({ hrefTo, Logo, key }) => (
      <a
        href={hrefTo}
        key={`link-${key}`}
        aria-label={`Patricia on Ice - ${key}`}
      >
        <LogoWrapper height={height}>
          <Logo color={color} />
        </LogoWrapper>
      </a>
    ))}
  </Wrapper>
)

const LogoWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  > svg {
    height: ${props => props.height};
    width: ${props => props.height};
    margin: 0 0.2vw;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

export default SocialMediaLinks
