import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { HEADER_HEIGHT } from "../../../components/header"
import MediaSlider from "../../../components/mediaSlider"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import {
  SubHeading,
  Padding,
  PinkDelimiter,
  styles,
  Text,
  StrongText,
} from "../../../common/styles"
import { media } from "../../../common/mediaQueries"
import { Button } from "../../../components/button"
import { clearAllBodyScrollLocks } from "body-scroll-lock"
import { navigate } from "gatsby"

const Training = () => (
  <StaticQuery
    query={graphql`
      query {
        kurs1: file(relativePath: { eq: "kurs1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        kurs2: file(relativePath: { eq: "kurs2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        kurs3: file(relativePath: { eq: "kurs3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        kurs4: file(relativePath: { eq: "kurs4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        kurs5: file(relativePath: { eq: "kurs5.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        kurs6: file(relativePath: { eq: "kurs6.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        kurs7: file(relativePath: { eq: "kurs7.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        kurs8: file(relativePath: { eq: "kurs8.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={({ kurs1, kurs2, kurs3, kurs4, kurs5, kurs6, kurs7, kurs8 }) => (
      <Wrapper>
        <MediaWrapper>
          <MediaSlider
            media={[
              { type: "image", content: kurs1, position: "50% 20%" },
              { type: "image", content: kurs2, position: "50% 30%" },
              { type: "image", content: kurs3, position: "50% 20%" },
              { type: "image", content: kurs4, position: "50% 0%" },
              { type: "image", content: kurs5, position: "78% 20%" },
              { type: "image", content: kurs6, position: "50% 20%" },
              { type: "image", content: kurs7, position: "50% 30%" },
              { type: "image", content: kurs8, position: "50% 35%" },
            ]}
          />
        </MediaWrapper>
        <ContentWrapper>
          <Padding value="2vw" axis="y" />
          <SubHeading>Persönliche Eislaufkurse</SubHeading>
          <Padding value="1vw" axis="y" />
          <PinkDelimiter />
          <Padding value="2vw" axis="y" />
          <StrongText color="blue">
            Lassen Sie sich von einem Profi trainieren!
          </StrongText>
          <Padding value="1vw" axis="y" />
          <p>
            <Text>
              Ob Klein oder Groß, Anfänger oder Profi, einzeln oder in kleinen
              Gruppen.
            </Text>
          </p>
          <p>
            <Text>
              Eislaufkurse und individuelles Training{" "}
              <Text color={styles.colors.magenta}>
                für alle Eiskunstlaufbegeisterten und jedes Niveau: Vom Anfänger
                bis hin zum Leistungssportler.
              </Text>
            </Text>
          </p>
          <p>
            <Text>
              Gern gebe ich mein Wissen weiter, das ich mir durch meine
              langjährige Erfahrung als Leistungssportlerin und Trainerin
              erworben habe.
            </Text>
          </p>
          <Text>
            Ich biete Ihnen:
            <Padding value="1vw" axis="y" />
            <ul>
              <li>
                Eislaufkurse für Kinder und Erwachsene (Anfänger,
                Fortgeschrittene, Profis) auf Ihrer Eisbahn
              </li>
              <li>
                <StyledAnchorLink
                  href="#video"
                  offset={HEADER_HEIGHT.desktop}
                  onClick={() => {
                    clearAllBodyScrollLocks()
                    navigate(null, { state: { modalXActive: undefined } })
                    window.history.go(-1)
                  }}
                >
                  Eislaufkurse auf Ihrem Event
                </StyledAnchorLink>
              </li>
              <li>Personal Training einzeln oder in kleinen Gruppen</li>
            </ul>
          </Text>
          <Padding value="2vw" axis="y" />
          <Button
            content="Interessiert? Jetzt Anfragen!"
            look="primary"
            linkTo={`/kontakt?2`}
          />
          <Padding value="4vw" axis="y" />
        </ContentWrapper>
      </Wrapper>
    )}
  />
)

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
  ${media.mobile} {
    flex-direction: column;
  }
`

const MediaWrapper = styled.div`
  flex: 0 0 50%;
  ${media.mobile} {
    flex: 0 0 30%;
  }
`
const StyledAnchorLink = styled(AnchorLink)`
  color: ${styles.colors.blue.dark};
`

const ContentWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 2vw;
  color: ${styles.colors.blue.dark};
  overflow: auto;
  ${media.mobile} {
    flex: 0 0 70%;
    padding: 5vw;
  }
  & > * {
    flex: 0 0 auto;
  }
  ${media.mobile} {
    width: 100%;
  }
`

export default Training
