import styled from "@emotion/styled"
import { Location } from "@reach/router"
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"
import { navigate } from "gatsby"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { media } from "../common/mediaQueries"

export const Modal = props => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (_.get(window, "history.state.modalXActive") !== undefined) {
      disableBodyScroll(null, {
        reserveScrollBarGap: true,
      })
    } else clearAllBodyScrollLocks()
  })

  const handleCloseClick = () => {
    clearAllBodyScrollLocks()
    navigate(null, {
      state: { modalXActive: undefined },
      replace: true,
    })
  }

  return (
    <Location>
      {p => {
        return (
          <ModalWrapper
            visible={
              mounted && _.get(p, "location.state.modalXActive") === props.id
            }
          >
            <Clickable onClick={handleCloseClick} />
            <Cross onClick={handleCloseClick} />
            <CloseClick onClick={handleCloseClick} />
            <ModalContent>{props.children}</ModalContent>
          </ModalWrapper>
        )
      }}
    </Location>
  )
}

const ModalWrapper = styled.div`
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: "black";
  z-index: 1000;
`

const ModalContent = styled.div`
  position: fixed;
  top: 5vw;
  bottom: 5vw;
  right: 12vw;
  left: 12vw;
  background-color: white;
  > div {
    position: relative;
  }
  ${media.mobile} {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`

const Clickable = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
`

const Cross = styled.a`
  width: 3vw;
  height: 3vw;
  position: absolute;
  right: 3vw;
  top: 1vw;
  width: 3vw;
  height: 3vw;
  z-index: 1001;
  ${media.mobile} {
    right: 6vw;
    top: 5vw;
  }
  :before,
  :after {
    position: absolute;
    content: " ";
    height: 3vw;
    width: 0.3vw;
    background-color: white;
    ${media.mobile} {
      height: 9vw;
    }
  }
  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
  }
`

const CloseClick = styled.div`
  position: absolute;
  z-index: 1500;
  top: 1vw;
  right: 4.3vw;
  cursor: pointer;
  height: 3vw;
  width: 3vw;
  ${media.mobile} {
    top: 5vw;
    height: 9vw;
    width: 9vw;
  }
`

export default Modal
