import styled from "@emotion/styled"
import { media } from "./mediaQueries"

export const styles = {
  colors: {
    blue: {
      strong: "rgb(51, 189, 234)",
      weak: "rgb(205, 242, 255)",
      dark: "#175380",
      darkest: "#134162",
      transparent: "#E6ECF1",
    },
    white: "white",
    magenta: "#CE0676",
    violet: "#804275",
    lightGrey: "#F1F1F1",
  },
}

// Abstract Styles

export const Absolute = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

// Text Styles

export const Heading = styled.span`
  font-size: 2.5vw;
  line-height: 1;
  font-weight: normal;
  letter-spacing: 0.2vw;
  text-transform: uppercase;
  color: ${props => props.color};
  ${media.mobile} {
    font-size: 8vw;
  }
`

export const SubHeading = styled.span`
  font-size: 1.5vw;
  font-weight: normal;
  color: ${props => props.color};
  ${media.mobile} {
    font-size: 6vw;
  }
`

export const SubtleSubHeading = styled.span`
  font-size: 1.75vw;
  ${media.mobile} {
    font-size: 3.25vw;
    font-weight: normal;
  }
`

export const StrongText = styled.span`
  font-weight: bold;
  color: ${props =>
    props.color === "blue" ? styles.colors.blue.dark : "white"};
  ${media.mobile} {
    font-size: 3vw;
  }
  ${media.wideDesktop} {
    font-size: 0.75vw;
    * {
      font-size: 0.75vw;
    }
  }
`

export const Text = styled.span`
  color: ${props => (props.color ? props.color : "inherit")};
  ${media.mobile} {
    font-size: 3vw;
    * {
      font-size: 3vw;
    }
  }
  ${media.wideDesktop} {
    font-size: 0.9vw;
    * {
      font-size: 0.9vw;
    }
  }
`

export const MenuText = styled.span`
  color: ${props => (props.color ? props.color : "inherit")};
  ${media.desktop} {
    font-size: 1vw;
  }
  ${media.wideDesktop} {
    font-size: 22px;
  }
  ${media.mobile} {
    font-size: 6vw;
  }
`

// Helpers

export const Padding = styled.div`
  content: " ";
  height: ${props => (props.axis === "y" ? props.value : 0)};
  width: ${props => (props.axis === "x" ? props.value : 0)};
  ${media.mobile} {
    height: ${props => (props.axis === "y" ? `calc(${props.value}*2)` : 0)};
    width: ${props => (props.axis === "x" ? `calc(${props.value}*2)` : 0)};
  }
`

export const PinkDelimiter = styled.div`
  height: 0.25vw;
  width: ${props => (props.width ? props.width : "5vw")};
  background-color: ${styles.colors.magenta};
  ${media.mobile} {
    height: 0.65vw;
    width: ${props => (props.width ? props.width : "15vw")};
  }
`

// Elements

export const Arrow = styled.i`
  display: inline-block;
  padding: 1vw;
  transform: ${props =>
    props.direction === "right" ? "rotate(-45deg)" : "rotate(135deg)"};
`

// Forms
export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const Formfield = styled.input`
  flex: 1 1 auto;
  background-color: ${styles.colors.blue.transparent};
  justify-content: center;
  border: none;
  padding: 0.75vw;
  margin: 0.5vw;
  font-size: 1.5vw;
  ${media.mobile} {
    font-size: 4vw;
    padding: 2vw;
    margin: 1.5vw 0;
  }
  ${media.wideDesktop} {
    font-size: 0.8vw;
  }
  color: ${styles.colors.blue.dark};
  ::placeholder {
    color: ${styles.colors.blue.dark};
    opacity: 1;
    font-size: 1.5vw;
    ${media.mobile} {
      font-size: 4vw;
    }
    ${media.wideDesktop} {
      font-size: 0.8vw;
    }
  }
`

export const Textarea = styled.textarea`
  flex: 1 1 auto;
  background-color: ${styles.colors.blue.transparent};
  border: none;
  height: 15vw;
  padding: 0.75vw;
  margin: 0.5vw;
  font-size: 1.5vw;
  line-height: 2vw;
  color: ${styles.colors.blue.dark};
  ${media.mobile} {
    font-size: 4vw;
    padding: 2vw;
    margin: 1.5vw 0;
    height: 30vh;
    line-height: 4.4vw;
  }
  ${media.wideDesktop} {
    font-size: 0.8vw;
    height: 20vh;
  }
  ::placeholder {
    color: ${styles.colors.blue.dark};
    opacity: 1;
    font-size: 1.5vw;
    ${media.mobile} {
      font-size: 4vw;
    }
    ${media.wideDesktop} {
      font-size: 0.8vw;
    }
  }
`
