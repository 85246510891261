import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Footer, { FOOTER_HEIGHT } from './footer'
import styled from '@emotion/styled'
import Header, { HEADER_HEIGHT } from './header'
import GlobalStyles from '../common/globalStyles'
import { media } from '../common/mediaQueries'
import { Global } from "@emotion/core"
import { Modal } from "./modal"
import Training from "../page-content/index/showcase/training"
import Choreographie from "../page-content/index/showcase/choreografie"
import IceShow from "../page-content/index/showcase/iceshow"


const Layout = ({ children }) => {
  return (
  <>
  <Global styles={GlobalStyles} />
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
      <Wrapper>
        <Header />
        <StyledMain>
          {children}
        </StyledMain>
        <Footer />
      </Wrapper>
      {Modals}
      </>
    )}
  />
  </>
)}

const Modals = [
  <Modal key="training" id="training" children={<Training />} />,
  <Modal
    key="choreografie"
    id="choreografie"
    children={<Choreographie />}
  />,
  <Modal key="show" id="show" children={<IceShow />} />,
]

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const StyledMain = styled.main`
  min-height: calc(100vh - ${HEADER_HEIGHT.desktop} - ${FOOTER_HEIGHT.desktop});
  max-width: 100%;
  overflow: hidden;
  ${media.mobile} {
    min-height: calc(100vh - ${HEADER_HEIGHT.mobile} - ${FOOTER_HEIGHT.mobile});
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout