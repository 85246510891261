import styled from "@emotion/styled"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { styles } from "../common/styles"

const style = {
  primary: {
    default: {
      color: styles.colors.blue.dark,
    },
    active: {
      color: styles.colors.blue.strong,
    },
  },
  secondary: {
    default: {
      color: styles.colors.white,
    },
    active: {
      color: styles.colors.magenta,
    },
  },
}

export const Link = ({ active, type, children, to, ...rest }) => (
  <StyledGatsbyLink active={active} type={type} to={to} {...rest}>
    {children}
  </StyledGatsbyLink>
)

const StyledGatsbyLink = styled(GatsbyLink)`
  text-transform: uppercase;
  color: ${props =>
    props.active === true
      ? style[props.type].active.color
      : style[props.type].default.color};
  text-decoration: none;
`
