import React, { useState } from "react"

import Arrow from "./arrow"
import styled from "@emotion/styled"
import { styles } from "../common/styles"

import Img from "gatsby-image/withIEPolyfill"
import { media } from "../common/mediaQueries"

const MediaSlider = ({ media }) => {
  const [currentMedia, setCurrentMedia] = useState(0)
  return (
    <Wrapper>
      {media.map((medium, idx) => {
        return medium.type === "image" ? (
          <ImageWrapper
            key={idx}
            visible={
              ((currentMedia % media.length) + media.length) % media.length ===
              idx
            }
          >
            <Img
              alt="Patricia On Ice - Foto"
              fluid={medium.content.childImageSharp.fluid}
              style={{ height: "101%" }}
              objectFit='cover'
              objectPosition={medium.position || '50% 50%'}
            />
          </ImageWrapper>
        ) : (
          <ImageWrapper
            key={idx}
            visible={
              ((currentMedia % media.length) + media.length) % media.length ===
              idx
            }
          >
            <iframe
              src={medium.content}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              width="100%"
              height="100%"
              title="Patricia on Ice - Video"
            />
          </ImageWrapper>
        )
      })}
        <LeftCenter
          onClick={() => setCurrentMedia(current => current - 1)}
          children={<ArrowLeft color={styles.colors.white} />}
        />
        <RightCenter
          onClick={() => setCurrentMedia(current => current + 1)}
          children={<ArrowRight color={styles.colors.white} />}
        />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: ${props => props.visible ? "block" : "none"}!important;
  ${media.mobile} {
    height: 30vh;
  }
`

const ArrowLeft = styled(Arrow)`
  transform: rotate(90deg);
  height: 2vw;
  width: 3vw;
  cursor: pointer;
  ${media.mobile} {
    height: 10vw;
    width: 10vw;
  }
`

const ArrowRight = styled(Arrow)`
  transform: rotate(-90deg);
  height: 2vw;
  width: 3vw;
  cursor: pointer;
  ${media.mobile} {
    height: 10vw;
    width: 10vw;
  }
`

const LeftCenter = styled.div`
  float: left;
  position: absolute;
  top: 50%;
  left: 0;
`

const RightCenter = styled.div`
  float: right;
  position: absolute;
  top: 50%;
  right: 0;
`

export default MediaSlider
