import { Link } from "gatsby"
import { styles, Text } from "../common/styles"
import styled from "@emotion/styled"
import React from "react"
import _ from "lodash"
import { media } from "../common/mediaQueries"

const style = {
  primary: {
    default: {
      fill: styles.colors.magenta,
      stroke: "none",
      textColor: "white",
    },
    hover: {
      color: styles.colors.blue.strong,
    },
    click: {
      color: styles.colors.blue.strong,
    },
  },
  secondary: {
    default: {
      fill: "transparent",
      stroke: `.1vw solid ${styles.colors.magenta}`,
      textColor: styles.colors.magenta,
    },
    hover: {
      color: styles.colors.blue.strong,
    },
    click: {
      color: styles.colors.blue.strong,
    },
  },
}

export const Button = ({ look, content, linkTo }) => (
  <StyledLink to={linkTo}>
    <StyledButton look={look}>
      <Text as="span">{_.toUpper(content)}</Text>
    </StyledButton>
  </StyledLink>
)

export const LinklessButton = ({ look, content, linkTo }) => (
  <StyledButton look={look}>
    <Text as="span">{_.toUpper(content)}</Text>
  </StyledButton>
)

const StyledButton = styled.button`
  border-radius: 9999px;
  vertical-align: text-top;
  padding: 0.75vw 2vw;
  cursor: pointer;
  background-color: ${props => style[props.look].default.fill};
  border: ${props => style[props.look].default.stroke};
  color: ${props => style[props.look].default.textColor};
  margin: 0 auto;
  ${media.wideDesktop} {
    padding: 0.2vw 1.25vw;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`
