import React from "react"

const Arrow = ({color, className}) => (
  <div className={className}>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="1.5"
    clipRule="evenodd"
    viewBox="0 0 960 680"
  >
    <path
      fill="none"
      stroke={color}
      strokeWidth="46.3"
      d="M58.318 129.159L480 550.841l421.682-421.682"
    />
  </svg>
  </div>
)

export default Arrow
