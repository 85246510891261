import React, { useState } from "react"
import styled from "@emotion/styled"
import Logo from "../images/logo.svg"
import SocialMediaLinks from "./socialmedia"
import { Link } from "./link"
import { styles, MenuText } from "../common/styles"
import { media } from "../common/mediaQueries"
import { Location } from "@reach/router"

export const HEADER_HEIGHT = { desktop: "80px", mobile: "80px" }

//TODO: Add correct links to navItems
const navItems = [
  {
    name: "Über mich",
    linkTo: "/",
  },
  {
    name: "Aktuelles",
    linkTo: "/aktuelles",
  },
  {
    name: "Referenzen",
    linkTo: "/referenzen",
  },
  {
    name: "Presse",
    linkTo: "/presse",
  },
  {
    name: "Kontakt",
    linkTo: "/kontakt",
  },
]

const Header = () => {
  const [mobileMenuActive, setMobileMenuActive] = useState(false)

  return (
    <Location>
      {({ location }) => (
        <Menu>
          <Link to="/" type="primary">
            <StyledLogo aria-label="Patricia on Ice - Logo" />
          </Link>
          <Navigation visible={mobileMenuActive}>
            {navItems.map((navItem, idx) => (
              <ListItem key={`navItem-${idx}`}>
                <Link to={navItem.linkTo} type="primary">
                  <StyledText active={location.pathname === navItem.linkTo}>
                    {navItem.name}
                  </StyledText>
                </Link>
              </ListItem>
            ))}
            <MobileSocialMediaLinks height="10vw" />
          </Navigation>
          <DesktopSocialMediaLinks height="25px" />
          <MenuSymbol onClick={() => setMobileMenuActive(!mobileMenuActive)}>
            <Block />
            <Block />
            <Block />
          </MenuSymbol>
        </Menu>
      )}
    </Location>
  )
}

const Menu = styled.div`
  position: sticky;
  z-index: 500;
  top: 0;
  background-image: linear-gradient(
    to right,
    white,
    white,
    ${styles.colors.blue.weak}
  );
  display: flex;
  height: ${HEADER_HEIGHT.desktop};
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  ${media.mobile} {
    height: ${HEADER_HEIGHT.mobile};
    padding-right: 0;
  }
`

const StyledLogo = styled(Logo)`
  height: ${HEADER_HEIGHT.desktop};
  width: 180px;
  ${media.mobile} {
    height: ${HEADER_HEIGHT.mobile};
  }
`

const Navigation = styled.div`
  z-index: 400;
  display: flex;
  text-align: center;
  ${media.mobile} {
    height: auto;
    max-height: calc(100vh - ${HEADER_HEIGHT.mobile});
    flex-direction: column;
    position: absolute;
    overflow-y: auto;
    top: calc(${HEADER_HEIGHT.mobile});
    left: 0;
    text-align: right;
    width: 100%;
    padding-right: 20px;
    background-image: linear-gradient(
      to right,
      white,
      white,
      ${styles.colors.blue.weak}
    );
    transition: transform 0.1s ease-in-out;
    transform: ${props => (props.visible ? "scaleY(1)" : "scaleY(0)")};
    transform-origin: top;
  }
`

const ListItem = styled.span`
  margin: 0 15px;
  white-space: nowrap;
  ${media.mobile} {
    margin: 10px 0;
    &:last-child {
      margin-bottom: 20px;
    }
  }
`

const DesktopSocialMediaLinks = styled(SocialMediaLinks)`
  ${media.mobile} {
    display: none;
  }
`

const StyledText = styled(MenuText)`
  color: ${props =>
    props.active ? styles.colors.blue.strong : styles.colors.blue.dark};
`

// Mobile-only

const MenuSymbol = styled.div`
  display: none;
  ${media.mobile} {
    margin-right: 20px;
    display: block;
    cursor: pointer;
  }
`

const Block = styled.div`
  width: 45px;
  height: 4px;
  background-color: ${styles.colors.blue.dark};
  margin: 6px 0;
`

const MobileSocialMediaLinks = styled(SocialMediaLinks)`
  align-self: flex-end;
  padding: 5vw 0;
  ${media.desktop} {
    display: none;
  }
`

export default Header
