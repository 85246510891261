import React from "react"
import styled from "@emotion/styled"
import SocialMediaLinks, { SocialMediaMap } from "./socialmedia"
import _ from "lodash"
import { Link } from "./link"
import { styles, Text } from "../common/styles"
import { media } from "../common/mediaQueries"

export const FOOTER_HEIGHT = { desktop: "140px", mobile: "28vw" }

const navItems = [
  {
    name: "Kontakt",
    linkTo: "/kontakt",
  },
  {
    name: "Impressum",
    linkTo: "/impressum",
  },
  {
    name: "Datenschutz",
    linkTo: "/datenschutz",
  },
]

const Footer = () => (
  <Wrapper>
    <FooterMenu>
      <Navigation>
        {navItems.map((navItem, idx) => (
          <ListItem key={`navItem-${idx}`}>
            <Link to={navItem.linkTo} type="secondary">
              <Text>{navItem.name}</Text>
            </Link>
          </ListItem>
        ))}
      </Navigation>
      <StyledSocialMediaLinks color="white" height="25px" />
    </FooterMenu>
    <Signature>
      <Text>
        © Patricia Kühne <DesktopDelimiter /> <MobileBreak /> Telefon +49 174
        9821468 | {_.keyBy(SocialMediaMap, "key").mail.identifier}
      </Text>
    </Signature>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

// Footer Menu and SocialMediaLinks

const FooterMenu = styled.div`
  height: calc(${FOOTER_HEIGHT.desktop} / 2);
  width: 100%;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${styles.colors.blue.dark};
  ${media.mobile} {
    height: calc(${FOOTER_HEIGHT.mobile} / 2);
  }
`

const Navigation = styled.div`
  display: flex;
  justify-content: flex-start;
`

const ListItem = styled.span`
  width: 100%;
  margin: 0 20px;
  white-space: nowrap;
`

const StyledSocialMediaLinks = styled(SocialMediaLinks)`
  ${media.mobile} {
    display: none;
  }
`

// Signature

const Signature = styled.div`
  height: calc(${FOOTER_HEIGHT.desktop} / 2);
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${styles.colors.blue.darkest};
  ${media.mobile} {
    height: calc(${FOOTER_HEIGHT.mobile} / 2);
  }
`

const DesktopDelimiter = styled.span`
  ${media.desktop} {
    &:after {
      content: "|";
    }
  }
`

const MobileBreak = styled.br`
  display: none;
  ${media.mobile} {
    display: block;
  }
`

export default Footer
