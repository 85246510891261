import styled from "@emotion/styled"
import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { media } from "../../../common/mediaQueries"
import {
  Padding,
  PinkDelimiter,
  StrongText,
  styles,
  SubHeading,
  Text,
} from "../../../common/styles"
import { Button } from "../../../components/button"
import MediaSlider from "../../../components/mediaSlider"

const IceShow = () => (
  <StaticQuery
    query={graphql`
      query {
        show1: file(relativePath: { eq: "show1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show2: file(relativePath: { eq: "show2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show3: file(relativePath: { eq: "show3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show4: file(relativePath: { eq: "show4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show5: file(relativePath: { eq: "show5.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show6: file(relativePath: { eq: "show6.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show7: file(relativePath: { eq: "show7.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show8: file(relativePath: { eq: "show8.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show9: file(relativePath: { eq: "show9.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show10: file(relativePath: { eq: "show10.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show11: file(relativePath: { eq: "show11.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show12: file(relativePath: { eq: "show12.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show13: file(relativePath: { eq: "show13.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show14: file(relativePath: { eq: "show14.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show15: file(relativePath: { eq: "show15.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show16: file(relativePath: { eq: "show16.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show17: file(relativePath: { eq: "show17.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show18: file(relativePath: { eq: "show18.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        show19: file(relativePath: { eq: "show19.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={({
      show1,
      show2,
      show3,
      show4,
      show5,
      show6,
      show7,
      show8,
      show9,
      show10,
      show11,
      show12,
      show13,
      show14,
      show15,
      show16,
      show17,
      show18,
      show19,
    }) => (
      <Wrapper>
        <MediaWrapper>
          <MediaSlider
            media={[
              {
                type: "video",
                content:
                  "https://www.youtube-nocookie.com/embed/Ye3SJRfzyws?mute=1&loop=1&showinfo=0&controls=0?rel=0?playsinline=1?modestbranding=1",
              },
              { type: "image", content: show1, position: "80% 35%" },
              { type: "image", content: show2 },
              { type: "image", content: show3, position: "50% 20%" },
              { type: "image", content: show4 },
              { type: "image", content: show5, position: "20% 30%" },
              { type: "image", content: show6, position: "20% 50%" },
              { type: "image", content: show7, position: "70% 30%" },
              { type: "image", content: show8, position: "50% 20%" },
              { type: "image", content: show9, position: "50% 20%" },
              { type: "image", content: show10, position: "50% 10%" },
              { type: "image", content: show11, position: "50% 20%" },
              { type: "image", content: show12, position: "30% 20%" },
              { type: "image", content: show13, position: "50% 30%" },
              { type: "image", content: show14, position: "50% 20%" },
              { type: "image", content: show15, position: "60% 20%" },
              { type: "image", content: show16, position: "60% 40%" },
              { type: "image", content: show17, position: "50% 20%" },
              { type: "image", content: show18, position: "50% 20%" },
              { type: "image", content: show19, position: "30% 20%" },
            ]}
          />
        </MediaWrapper>
        <ContentWrapper>
          <Padding value="2vw" axis="y" />
          <SubHeading>Ihre persönliche Ice Show</SubHeading>
          <Padding value="1vw" axis="y" />
          <PinkDelimiter />
          <Padding value="2vw" axis="y" />
          <Text>
            <StrongText color="blue">
              Machen Sie Ihr Firmenevent oder Ihre private Feier zu etwas ganz
              Besonderem:
            </StrongText>
            <Padding value="1vw" axis="y" />
            <p>
              <Text color={styles.colors.magenta}>
                Auf einer mobilen Eisbahn oder einer vorhandenen Eisbahn{" "}
              </Text>
              erleben Sie eine persönlich gestaltete, unvergessliche Eislaufshow
              für Sie und Ihre Gäste. Lassen Sie sich faszinieren von einem oder
              mehreren Eiskunstläufern in phantasievollen Kostümen. Begleitet
              von inspirierender Livemusik, flankiert von weiteren Künstlern wie
              Feuerkünstlern oder Akrobaten.{" "}
            </p>
            <p>
              {" "}
              Individuell oder nach Auswahl aus einem großen Repertoire an
              verschiedensten Themen: Weihnachten, Märchen und Prinzessin, Ice &
              Fire, Lichtshow, Winter oder Sommer.{" "}
            </p>
            <p>
              Lust auf ein besonderes Highlight? Binden Sie Ihre Gäste aktiv ein
              und lernen Sie gemeinsam mit einem Profi das Eiskunstlaufen oder
              erarbeiten Sie gemeinsam als Team eine eigene Kür.
            </p>
          </Text>
          <Padding value="1.5vw" axis="y" />
          <Text>
            Welcher Anlass soll es für Sie sein?{" "}
            <Text color={styles.colors.magenta}>
              Egal ob Eisbahneröffnung, Produktpräsentation, Weihnachtsfeier,
              Firmenevent oder Hochzeit: Mit viel Fingerspitzengefühl und
              Leidenschaft gestalte ich ein Programm für Sie, das Ihren Gästen
              dauerhaft in Erinnerung bleiben wird.
            </Text>
          </Text>
          <Padding value="2vw" axis="y" />
          <Button
            content="Interessiert? Jetzt Anfragen!"
            look="primary"
            linkTo={`/kontakt`}
          />
          <Padding value="4vw" axis="y" />
        </ContentWrapper>
      </Wrapper>
    )}
  />
)

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
  ${media.mobile} {
    flex-direction: column;
  }
`

const MediaWrapper = styled.div`
  flex: 0 0 50%;
  ${media.mobile} {
    flex: 0 0 30%;
  }
`

const ContentWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 2vw;
  color: ${styles.colors.blue.dark};
  overflow: auto;
  ${media.mobile} {
    flex: 0 0 70%;
    padding: 5vw;
  }
  & > * {
    flex: 0 0 auto;
  }
  ${media.mobile} {
    width: 100%;
  }
`

export default IceShow
