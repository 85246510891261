import styled from "@emotion/styled"
import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { media } from "../../../common/mediaQueries"
import {
  Padding,
  PinkDelimiter,
  StrongText,
  styles,
  SubHeading,
  Text,
} from "../../../common/styles"
import { Button } from "../../../components/button"
import MediaSlider from "../../../components/mediaSlider"

const Choreographie = () => (
  <StaticQuery
    query={graphql`
      query {
        choreo1: file(relativePath: { eq: "choreo1.png" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        choreo2: file(relativePath: { eq: "choreo2.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        choreo3: file(relativePath: { eq: "choreo3.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={({ choreo1, choreo2, choreo3 }) => (
      <Wrapper>
        <MediaWrapper>
          <MediaSlider
            media={[
              { type: "image", content: choreo1, position: "70% 30%" },
              { type: "image", content: choreo2, position: "40% 10%" },
              { type: "image", content: choreo3, position: "50% 20%" },
            ]}
          />
        </MediaWrapper>
        <ContentWrapper>
          <Padding value="2vw" axis="y" />
          <SubHeading>Choreographie mit Herz</SubHeading>
          <Padding value="1vw" axis="y" />
          <PinkDelimiter />
          <Padding value="2vw" axis="y" />
          <StrongText color="blue">
            Entwicklung individualisierter Choreografien.
          </StrongText>
          <Padding value="2vw" axis="y" />
          <Text>
            Für Leistungssportler und ambitionierte Eiskunstläufer entwickle ich
            neue Choreografien für das Kurzprogramm und die Kür oder überarbeite
            diese. Inbegriffen sind Recherche geeigneter Musik und
            professioneller Musikschnitt im Tonstudio
          </Text>
          <Padding value="2vw" axis="y" />
          <Text color={styles.colors.magenta}>
            Konzeption von Choreografien für Ihr Event z.B. für Ihre Fashion
            Show, Dance Show oder individuelle Shows.
          </Text>
          <Padding value="2vw" axis="y" />
          <Button
            content="Interessiert? Jetzt Anfragen!"
            look="primary"
            linkTo={`/kontakt?1`}
          />
          <Padding value="4vw" axis="y" />
        </ContentWrapper>
      </Wrapper>
    )}
  />
)

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
  ${media.mobile} {
    flex-direction: column;
  }
`

const MediaWrapper = styled.div`
  flex: 0 0 50%;
  ${media.mobile} {
    flex: 0 0 30%;
  }
`

const ContentWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 2vw;
  color: ${styles.colors.blue.dark};
  overflow: auto;
  ${media.mobile} {
    flex: 0 0 70%;
    padding: 5vw;
  }
  & > * {
    flex: 0 0 auto;
  }
  ${media.mobile} {
    width: 100%;
  }
`

export default Choreographie
