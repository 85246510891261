import { css } from "@emotion/core"
import futuraBoldEot from "./futura-bold/font.eot"
import futuraBoldTtf from "./futura-bold/font.ttf"
import futuraBoldWoff from "./futura-bold/font.woff"
import futuraBoldWoff2 from "./futura-bold/font.woff2"
import futuraLightEot from "./futura-light/font.eot"
import futuraLightTtf from "./futura-light/font.ttf"
import futuraLightWoff from "./futura-light/font.woff"
import futuraLightWoff2 from "./futura-light/font.woff2"

export const futuraLight = '"Futura LT W01 Light"'
export const futuraBold = '"Futura LT W01 Bold"'

export const fontFaceDefinitions = css`
  ${
    process.env.NODE_ENV !== "production"
      ? ""
      : css`
          @import url("https://fast.fonts.net/lt/1.css?apiType=css&c=65e54e23-ffa3-4a50-a790-14f83b0c008e&fontids=1475786,1475822");
        `
  }
  @font-face {
    font-family: ${futuraLight};
    font-display: auto;
    src: url('${futuraLightEot}?#iefix');
    src: url('${futuraLightEot}?#iefix') format('eot'),
      url('${futuraLightWoff2}') format('woff2'),
      url('${futuraLightWoff}') format('woff'),
      url('${futuraLightTtf}') format('truetype');
  }
  @font-face {
    font-display: auto;
    font-family: ${futuraBold};
    src: url('${futuraBoldEot}?#iefix');
    src: url('${futuraBoldEot}?#iefix') format('eot'),
      url('${futuraBoldWoff2}') format('woff2'),
      url('${futuraBoldWoff}') format('woff'),
      url('${futuraBoldTtf}') format('truetype');
  }
`
